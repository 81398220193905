<script setup lang="ts">
interface Props {
  fullName: string
  role: string
}
defineProps<Props>()

const profileStore = useProfileStore()
const companyName = computed(
  () => profileStore?.data.organisation?.trading_name,
)
</script>

<template>
  <div>
    <p v-if="role === 'administrator'">
      By assigning
      <span class="font-bold capitalize">
        {{ fullName }}
      </span>
      the
      <span class="font-bold text-primary"> Administrator </span>
      role, you acknowledge that
      <span class="font-bold capitalize">
        {{ fullName }}
      </span>
      is an Authorised Person that can make payments and appoint other
      Authorised Persons.
    </p>

    <p v-else-if="role === 'payer'">
      By assigning
      <span class="font-bold capitalize">
        {{ fullName }}
      </span>
      the <span class="font-bold text-primary"> Payer </span>
      role, you acknowledge that
      <span class="font-bold capitalize">
        {{ fullName }}
      </span>
      is an Authorised Person that can make payments on behalf of
      <span class="font-bold capitalize"> {{ companyName }}. </span>
    </p>

    <p v-else-if="role === 'payroll'">
      By assigning
      <span class="font-bold capitalize">
        {{ fullName }}
      </span>
      the <span class="font-bold text-primary"> Payroll </span>
      role, you acknowledge that
      <span class="font-bold capitalize">
        {{ fullName }}
      </span>
      is an Authorised Person that can manage and process payroll on behalf of
      <span class="font-bold capitalize"> {{ companyName }}. </span>
    </p>
  </div>
</template>
