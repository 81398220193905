import { useQuery } from '@tanstack/vue-query'
import { ROLES_QUERY_KEYS } from '~/constants/queryKeys/rolesQueryKeys'

export function getRolesQuery() {
  const { $api } = useNuxtApp()
  return useQuery({
    queryKey: ROLES_QUERY_KEYS.GET_ROLES,
    queryFn: $api.core.roles.getRoles,
    select(data) {
      return data.data
    },
  })
}
